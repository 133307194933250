import { template as template_a1e4d8f9607f4f77a079175bbc84a587 } from "@ember/template-compiler";
const WelcomeHeader = template_a1e4d8f9607f4f77a079175bbc84a587(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
