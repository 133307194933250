import { template as template_013df117b5f4447995bc62a2be9d1d48 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_013df117b5f4447995bc62a2be9d1d48(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
