import { template as template_801ead6afed7425581b414c7f6a34988 } from "@ember/template-compiler";
const FKLabel = template_801ead6afed7425581b414c7f6a34988(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
