import { template as template_13e672633d6b41c09277f7590a3b2302 } from "@ember/template-compiler";
const FKControlMenuContainer = template_13e672633d6b41c09277f7590a3b2302(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
